import React from 'react';
import Logo from '../../assets/images/logo.png';
import { Link } from 'gatsby';

import './header.scss';

const Header = props => (
  <>
    <header id="header">
      <div className="header-fixed container">
        <div className="logo-header">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="right-block">
          {props.title ? (
            <div className="button-orcamento">
              <Link to="/orcamento">ORÇAMENTO</Link>
            </div>
          ) : null}

          <nav role="navigation" id="nav-global">
            <div id="menuToggle">
              <input type="checkbox" />

              <span></span>
              <span></span>
              <span></span>

              <ul id="menu">
                <Link to="/">
                  <li>Home</li>
                </Link>

                <Link to="/sobre-nos">
                  <li>Sobre Nós</li>
                </Link>

                <Link to="/estamparia-digital">
                  <li>Estamparia Digital</li>
                </Link>

                <Link to="/portfolio">
                  <li>Portfólio</li>
                </Link>

                <Link to="/contato">
                  <li>Contato</li>
                </Link>

                <Link to="/orcamento">
                  <li>Orçamento</li>
                </Link>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
    {props.title ? (
      <section id="title-wrapper">
        <h1>{props.title}</h1>
      </section>
    ) : null}
  </>
);

export default Header;
