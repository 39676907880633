import React from 'react';
import LogoFooter from '../../assets/images/logo-footer.png';
import LogoLbm from '../../assets/images/logo-lbm.png';
import Facebook from '../../assets/images/facebook.png';
import Instagram from '../../assets/images/instagram.png';
import './footer.scss';

const Footer = props => (
  <>
    <footer id="footer">
      <div className="footer-wrapper container">
        <div className="text-footer">
          <p>
            Fundada em 2018, temos como objetivo gerar valor agregado ao serviço
            de impressão e sublimação em tecidos de alta qualidade, que oferece
            uma infinidade de produtos de manufatura para os mais diversos
            segmentos.
          </p>
        </div>
        <div className="logo-footer">
          <a href="/">
            <img src={LogoFooter} />
          </a>
        </div>
        <div className="contato-footer">
          <h1>Contato</h1>
          <p>
            E-mail: <br />
            <a href="mailto:sedafio@sedafio.com.br">
              sedafio@sedafio.com.br <br />
            </a>
            <br />
            Telefone: <br />
            <a
              href="https://api.whatsapp.com/send?phone=5511982043619&text=Ol%C3%A1%20Sedafio%2C%20estou%20com%20uma%20d%C3%BAvida!"
              target="_blank"
            >
              (11) 98204-3619
              <br />
            </a>
            <br />
            Endereço: <br />
            <a
              href="https://www.google.com/maps/place/Pra%C3%A7a+Trist%C3%A3o+da+Cunha,+56+-+%C3%81gua+Rasa,+S%C3%A3o+Paulo+-+SP,+03189-030/@-23.5694864,-46.5804582,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce5c1e88a84bcf:0x6e7d66f81b0930ae!8m2!3d-23.5694864!4d-46.5782695"
              target="_blank"
            >
              Praça Tristão da Cunha, 56 Água Rasa, São Paulo/SP
            </a>
            <br />
          </p>
        </div>
      </div>
      <div className="copyright-footer container">
        <div className="dev-by">
          <p>
            Desenvolvido por
            <a href="#">
              <img src={LogoLbm} />
            </a>
          </p>
        </div>
        <div className="copy">
          <p>
            © Copyright 2021 | Todos os Direitos Reservados | Sedafio Comércio e
            Indústria LTDA
          </p>
        </div>
        <div className="social">
          <p>
            <span>Siga nossas redes</span>
            <a href="https://www.facebook.com/sedafiotecidos/" target="_blank">
              <img src={Facebook} />
            </a>
            <a href="https://www.instagram.com/sedafioed/" target="_blank">
              <img src={Instagram} />
            </a>
          </p>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
